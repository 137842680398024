import cn from 'classnames';

import { components } from 'react-select';

const Option = props => (
  <components.Option {...props}>
    <div className="flex justify-between items-center text-gray-900">
      <div
        className={cn({
          'font-medium': props.data.residency,
          'font-normal': !props.data.residency
        })}
      >
        {props.children}
      </div>
      <div
        className={cn('justify-end text-sm px-1', {
          'text-gray-800': props.isFocused,
          'text-gray-600': !props.isFocused
        })}
      >
        {props.data.count}
      </div>
    </div>
  </components.Option>
);

export default Option;
