import { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';

import { useProgram, useSpecialty, useSearchState } from 'utils/store';

import Filters from 'components/SelectSpecialty/Filters';
import Option from 'components/SelectSpecialty/Option';
import Select from 'components/Select';

const customStyles = {
  menu: provided => ({ ...provided, marginTop: '2.25rem' })
};

const FilterBox = ({ children }) => (
  <div className="relative w-full z-100">
    {children}
    <div className="absolute flex w-full items-start py-2 px-2 bg-gray-300 shadow-xl">
      <Filters />
    </div>
  </div>
);

const SelectSpecialty = ({ loading, list, searchterm }) => {
  const router = useRouter();
  const [options, setOptions] = useState([]);

  const specialty = useSpecialty(state => state.specialty);
  const setSpecialty = useSpecialty(state => state.setSpecialty);

  const resetProgram = useProgram(state => state.resetProgram);

  const incFel = useSearchState(state => state.fellowship);
  const incRes = useSearchState(state => state.residency);

  const maxSelected = searchterm?.length > 2;

  // Filter list of specialties by type (residency or fellowship)
  const isChecked = o => (o.residency && incRes) || (!o.residency && incFel);

  const handleChange = useCallback(arr => {
    const path = arr
      ?.slice(0, 3)
      .map(o => o.value)
      .join('/_/');
    if (!path) return router.push('/specialty');
    router.push('/specialty/[[...param]]', `/specialty/${path}`);
    // Reset program so there's not an additional rerender.
    resetProgram();
  });

  // Set input value only after first render.
  useEffect(() => {
    if (list) {
      setOptions(list.filter(isChecked));
      setSpecialty(list.filter(o => searchterm?.includes(o.value)));
    }
  }, [list, incFel, incRes]);

  useEffect(() => {
    if (!specialty) resetProgram();
  }, [specialty]);

  return (
    <div className="md:mb-2">
      <Select
        aria-label="Select up to three specialties"
        customDropdownWrapper={FilterBox}
        components={{ Option }}
        onChange={handleChange}
        options={maxSelected ? [] : options}
        noOptionsMessage={() =>
          maxSelected ? 'You can select up to three specialties' : 'No options'
        }
        placeholder={loading ? 'Loading...' : 'Select up to three specialties'}
        styles={customStyles}
        value={specialty}
        isMulti
      />
    </div>
  );
};

export default SelectSpecialty;
