import { useSearchState } from 'utils/store';

const Checkbox = ({ checked, className, label, onClick, onChange }) => (
  <label
    className={`text-gray-700 font-normal cursor-pointer mr-3 ${className}`}
    onClick={onClick}
  >
    <input
      className="form-checkbox mr-2 leading-tight text-orange-500"
      defaultChecked={checked}
      type="checkbox"
      onChange={onChange}
    />
    <span className="text-sm">{label}</span>
  </label>
);

const Filters = props => {
  const fellowship = useSearchState(state => state.fellowship);
  const residency = useSearchState(state => state.residency);
  const setFellowship = useSearchState(state => state.setFellowship);
  const setResidency = useSearchState(state => state.setResidency);

  return (
    <>
      <Checkbox
        className="font-semibold"
        checked={residency}
        label="Residency"
        onChange={() => setResidency(!residency)}
      />
      <Checkbox
        checked={fellowship}
        label="Fellowship"
        onChange={() => setFellowship(!fellowship)}
      />
    </>
  );
};

export default Filters;
